#nav-bar {
	padding: 32px 10px;
	box-shadow: none;
	transition: padding-top 0.25s linear;
	.container {
		.withSidePadding {
			grid-template-columns: 19% 1% 80%;

			ul#desktop-menu {
				justify-self: flex-end;
				li {
					a {
						text-transform: uppercase;
						margin: 0px 24px;
						font-size: 9pt;
						font-weight: 300;
						letter-spacing: 0.92px;
					}

					&:last-of-type a {
						margin-right: 0px;
					}
				}
			}

			// Custom css to apply the mobile navbar layout when desktop window is 102px or less, other
			// the desktop menu links overflow onto 2 lines as there are more links
			//
			// Would be nice to figure out a way to do this more cleanly
			@media screen and (max-width: 1024px) {
				ul#desktop-menu {
					display: none;
				}

				#hamburger {
					justify-self: flex-end;
					display: flex;
				}
			}
		}
	}

	&.scrolled {
		transition: padding 0.25s linear;
		padding: 12px 10px;

		@media screen and (max-width: 480px) {
			padding: 10px;
		}
	}
}
