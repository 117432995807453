body {
	padding: 0;
	margin: 0;
	font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
	display: flex;
	flex-direction: column;
	scroll-behavior: smooth;
}

// Navbar override
@media screen and (prefers-color-scheme: dark) {
	body {
		background: #1e1e1e;
	}

	#nav-bar {
		background: rgba(0, 0, 0, 0.1);
	}
}

.button {
	color: white;
	background: orange;
	background-image: linear-gradient(90deg, #fad961 -50%, #f76b1c 100%);
	padding: 12px 24px;
	border-radius: 24px;
	cursor: pointer;
	text-decoration: none;
	border: none;
	font-size: 12pt;
	&:hover {
		background-image: linear-gradient(90deg, #fad961 0%, #f76b1c 100%);
	}

	@media (prefers-color-scheme: dark) {
		color: black;
	}
}

a.link {
	color: #454545;

	@media (prefers-color-scheme: dark) {
		color: rgba(210, 210, 210, 1);
	}

	text-decoration: underline;
}

ul {
	li {
		a {
			color: #454545;

			@media (prefers-color-scheme: dark) {
				color: rgba(210, 210, 210, 1);
			}

			text-decoration: underline;
		}
	}
}

.section-container {
	margin-top: 32px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	@media screen and (max-width: 720px) {
		flex-direction: column-reverse;
	}
}

.section-container-center {
	text-align: center;
}

form {
	text-align: center;
	display: inline-block;
	flex-direction: column;
	align-items: center;

	h1 {
		margin-top: 0px;
	}

	.input-group,
	.form-field {
		padding: 10px;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-between;
	}
}

table {
	padding: 0px;
	width: 100%;
	text-align: left;

	thead tr th {
		padding: 16px 24px;
		color: #565656;

		@media (prefers-color-scheme: dark) {
			color: rgba(190, 190, 190, 1);
		}

		font-weight: normal;
		text-align: left;
		border-bottom: solid 1px #dbdbdb;

		@media screen and (max-width: 720px) {
			padding: 8px;
		}
	}

	tbody tr td {
		padding: 16px 24px;
		color: #787878;

		@media (prefers-color-scheme: dark) {
			color: rgba(160, 160, 160, 1);
		}
		@media screen and (max-width: 720px) {
			padding: 8px;
		}

		a {
			color: #787878;

			@media (prefers-color-scheme: dark) {
				color: rgba(160, 160, 160, 1);
			}

			text-decoration: none;
		}
	}

	tbody tr:hover {
		background: rgba(0, 0, 0, 0.05);
	}
}

// Contact page textarea

#message-container {
	.form-field {
		flex-direction: column;
		align-items: flex-start;

		label {
			margin-bottom: 8px;
		}

		textarea {
			width: 100%;
			min-height: 120px;
		}
	}
	margin-bottom: 16px;
}

// Typography

h2 {
	font-weight: 300;
	margin-top: 48px;
	scroll-margin-top: 3em;
}
